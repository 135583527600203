<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="subject-list">
        <div class="subject-item" v-for="item in subjectList" :key="item.id" @click="handleClick(item)">
          <div class="subject-cover">
            <img :src="`${OSS_URL}/${item.pictureVertical}`" alt="" />
          </div>
          <div class="subject-introduction">
            <span class="subject-name">{{ item.name }}</span>
            <span class="subject-total" v-if="!item.externalLinksUrl">
              共{{ item.contentCount }}个
            </span>
          </div>
        </div>
      </div>
      <!-- 隐藏音乐播放按钮 原因：不确定以后需不需要-->
      <!-- <div class="btns">
        <div v-if="showMusicButton == true">
          <img :src="audio" alt="声音" @click="handleMusic(music)" />
        </div>
      </div> -->
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="3"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import back from '@/components/back'
import navRouter from '@/components/navRouter'
import { API_MODULE } from '@/request/api'
import { openUrl } from '@/utils/windowOpen'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from 'vuex'
const redAudio = require('@/assets/images/photo/audio.png')
const blueAudio = require('@/assets/images/photo/audio-blue.png')
const redPauseAudio = require('@/assets/images/photo/audio-pause.png')
const bluePauseAudio = require('@/assets/images/photo/audio-pause-blue.png')
export default {
  name: 'CustomizeCategory',
  data() {
    return {
      audio: redPauseAudio,
      icon: redIcon,
      subjectList: [],
      // music: "",
      // 是否显示播放按钮
      // showMusicButton: true,
      isPause: false
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.audio = redPauseAudio
    } else {
      this.icon = blueIcon
      this.audio = bluePauseAudio
    }
    // this.music = this.$route.query.music
    // if (this.music == null || this.music == undefined || this.music == "") {
    //   this.showMusicButton = false
    // } else {
    //   this.showMusicButton = true
    // }
    this.getData()

    this.useRouterList.find((router, i) => {
      if (!router) return
      if (router.path === this.$route.fullPath) {
        this.useRouterList.splice(i + 1)
      }
    })
    this.setRouterList(this.useRouterList, this.$route.fullPath)
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
      } else {
        this.icon = blueIcon
      }
    },
    $route: function () {
      this.getData()
    },
    isPause(val) {
      if (this.useSubject === 'red') {
        this.audio = val ? redAudio : redPauseAudio
      } else {
        this.audio = val ? blueAudio : bluePauseAudio
      }
    }
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getData() {
      this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: [this.$route.query.id]
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.subjectList = res.data.model
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },

    handleClick(item) {
      if (item.externalLinksUrl) {
        openUrl(item.externalLinksUrl)
      } else {
        this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
          catId: [item.id]
        })
          .then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.$router.push({
                path: `/layout/customize/${res.data.type}`,
                query: {
                  id: item.id
                }
              })

              // 保存下一级路径
              const path = `/layout/customize/${res.data.type}?id=${item.id}`
              this.useRouterList.push({
                name: item.name,
                path: path
              })
              this.setRouterList(this.useRouterList, path)
            } else {
              this.$message(res.msg)
            }
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
    },

    handleMusic(src) {
      this.isPause = !this.isPause
      if (src) {
        this.playOrPausedAudio(this.OSS_URL + src)
      } else {
        this.$message({ message: '请联系管理员上传音频', type: 'error' })
      }
    }
  },
  components: {
    menuPop,
    navRouter,
    back
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  .content {
    min-height: 24.2rem;
    padding: 0.4rem 0 0.4rem 0.4rem;
    box-sizing: border-box;
    @include background_color('background-medium');

    .title {
      font-size: 0.74rem;
      line-height: 0.74rem;
      font-weight: 400;
      color: #ffffff;
      margin-top: 1.3rem;
      text-align: center;
      margin-bottom: 0.4rem;
    }

    .sub-title {
      font-size: 0.37rem;
      line-height: 0.37rem;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
    }

    .subject-list {
      display: flex;
      flex-wrap: wrap;

      .subject-item {
        width: 6.6rem;
        height: 4.5rem;
        overflow: hidden;
        position: relative;
        margin-right: 0.4rem;
        margin-bottom: 0.4rem;
        cursor: pointer;

        .subject-cover {
          height: 100%;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .subject-introduction {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1.2rem;
          background-color: #cca766;
          opacity: 0.8;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.4rem;

          .subject-name {
            font-size: 0.36rem;
            font-weight: bold;
            color: #ffffff;
            opacity: 1;
          }

          .subject-total {
            font-size: 0.22rem;
            font-weight: 400;
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }

    .btns {
      position: fixed;
      top: 2rem;
      right: 0.68rem;

      div {
        margin-bottom: 0.6rem;

        img {
          width: 0.8rem;
        }
      }
    }
  }
}
</style>
