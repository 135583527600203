<template>
  <div>
    <el-drawer
      title="左侧边栏"
      :visible.sync="drawer"
      direction="ltr"
      size="6.4rem"
      :with-header="false"
    >
      <div class="list">
        <div
          :class="['item', activeItem == index ? 'item-acctive' : '']"
          @click="handleClick(item)"
          v-for="(item, index) in itemList"
          :key="item.id"
        >
          <img :src="`${OSS_URL}${item.iconName}`" alt="" class="icon-right" />
          <p class="name">{{ item.name }}</p>
        </div>
      </div>
    </el-drawer>
    <div
      class="open-close"
      @click="drawer = !drawer"
      :style="{ left: drawer ? '6.4rem' : '0' }"
    >
      <div v-if="!drawer">》</div>
      <div v-else>《</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  inject: ["reload"],
  data() {
    return {
      drawer: false,
      itemList: [],
    };
  },
  props: {
    activeItem: {
      type: Number,
      required: true
    }
  },
  methods: {
    // 获取滚动列表
    getApps() {
      this.POST(this.API_MODULE.HOME_APPLICATION)
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.itemList = res.data.appRIGHT;
            console.log(this.itemList)
          } else {
            this.$message({ message: res.msg, type: "success" });
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    handleClick(item) {
      // 此版本不开放智慧互动的功能
      if (item.code === 'WISDOM_INTERACTION') {
        //   return this.$alert('此功能暂未开放！', '提示', {
        //     confirmButtonText: '确定'
        //   })
        return this.$router.push({ path: `/layout/interactive` })
      }
      this.$router.push({ path: `/layout/app/${item.code}/${item.id}` });
      this.$nextTick(() => (this.reload()))
    },
  },
  created() {
    console.log(this.activeItem)
    this.getApps();
  },
};
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";
.list {
  width: 6.4rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  /*opacity: 0.8;*/
  padding: 0.9rem 0.45rem;
  box-sizing: border-box;
  position: relative;
  @include background_color("background-low");

  .item {
    width: 2.64rem;
    height: 2.64rem;
    /*border-style: solid;*/
    /*border-width: 0.05rem;*/
    /*border-radius: 0.1rem;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*opacity: 0.8;*/
    box-sizing: border-box;
    img {
      width: 0.65rem;
      height: 0.65rem;
      margin-bottom: 0.2rem;
    }

    .name {
      font-size: 0.33rem;
      @include font_color("font-medium");
    }
  }

  .item-acctive {
    background-color: #ffffff;
    opacity: 0.54;
    border-radius: 0.2rem;
  }
}

.open-close {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.6, 1, 0.1, 1);
  width: 0.38rem;
  height: 2.3rem;
  font-size: 0.34rem;
  color: #ffffff;
  line-height: 2.3rem;
  background: #cca766;
  opacity: 0.79;
  border-radius: 0 0.4rem 0.4rem 0;
  z-index: 99999;
}
</style>
